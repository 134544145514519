import queryString from "query-string"
import React, { Fragment, useRef, useState, useEffect } from "react"

import {
  StyledSuccessDetailTitle,
  StyledSuccessPageImage,
} from "./index.styles"

import { loadConnectAndInitialize } from "@stripe/connect-js"
import { FormattedMessage, useIntl, Link } from "gatsby-plugin-intl"
import { useAuth } from "@tmu/src/hooks"
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
} from "@stripe/react-connect-js"
import { Button, LazyImage } from "@tmu/components/common"

const { CLOUDFLARE_IMAGE_URL } = process.env

const StripePage = ({ onClick, errors, createStripeOnboardingAccount }) => {
  const paramOptions = { arrayFormat: "comma" }
  const params = queryString.parse(location.search, paramOptions)
  const merchantId = params?.merchant
  const isMerchantOnline = params?.type === "online"
  const isMerchantOffline = params?.type === "offline"
  const isMerchantInternal = params?.type === "internal"
  const isMerchantCorporate = false
  const [isEmbeddedOpen, setEmbeddedOpen] = useState(false)
  const [isContinueButtonVisible, setContinueButtonVisible] = useState(false)
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null) // Initialize Stripe Connect instance
  const { user } = useAuth()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)

  const handleEmbedded = () => {
    setLoading(true)
    createStripeOnboardingAccount({
      variables: {
        id: merchantId,
        type: "merchant",
      },
    })
      .then((response) => {
        const accountSecret =
          response.data.createStripeOnboardingAccount?.accountSecret
        if (accountSecret) {
          const instance = loadConnectAndInitialize({
            publishableKey: process.env.STRIPE_PUBLIC_API_KEY,
            fetchClientSecret: () => Promise.resolve(accountSecret),
          })
          setStripeConnectInstance(instance)
          setEmbeddedOpen(true)
        }
      })
      .catch((error) => {
        console.error("Registration error", error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return isEmbeddedOpen ? (
    <>
      {loading && <p>Loading...</p>}
      {errors && <p style={{ color: "red" }}>{JSON.stringify(errors)}</p>}
      {stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <h3>Stripe Account Onboarding</h3>
          <ConnectAccountOnboarding
            collectionOptions={{
              fields: "eventually_due",
              futureRequirements: "include",
            }}
            onExit={() => setContinueButtonVisible(true)}
          />
        </ConnectComponentsProvider>
      )}
      {isContinueButtonVisible ? (
        <Button
          type="button"
          color="carrot"
          className="stripe-button"
          onClick={onClick}>
          <FormattedMessage id="ui::continue" defaultMessage="Continue" />
        </Button>
      ) : null}
    </>
  ) : (
    <>
      <StyledSuccessDetailTitle data-testid="donation-success-details">
        <FormattedMessage
          id="merchant::selfRegistrationForm::thanks"
          defaultMessage="Thank you for joining TrustMeUp"
        />{" "}
        {isMerchantOnline ? (
          <FormattedMessage
            id="merchant::selfRegistrationForm::online"
            defaultMessage="as an online merchant"
          />
        ) : isMerchantOffline ? (
          <FormattedMessage
            id="merchant::selfRegistrationForm::offline"
            defaultMessage="as a store"
          />
        ) : isMerchantInternal ? (
          <FormattedMessage
            id="merchant::selfRegistrationForm::internal"
            defaultMessage="an internal merchant"
          />
        ) : (
          ""
        )}
        !
      </StyledSuccessDetailTitle>
      <StyledSuccessPageImage>
        <LazyImage
          altName="donation-success"
          src={CLOUDFLARE_IMAGE_URL + "/static/assets/images/icon-trust.svg"}
          width="400"
          fit="cover"
        />
      </StyledSuccessPageImage>
      <div style={{ textAlign: "center" }}>
        <FormattedMessage
          id="merchant::selfRegistrationForm::ready"
          defaultMessage="You're almost ready to start selling your service!"
          tagName="p"
        />
        <br />
        <span>
          <FormattedMessage
            id="merchant::selfRegistrationForm::started"
            defaultMessage="To get started, we need to set up payment processing through Stripe."
          />
        </span>

        <p>
          <FormattedMessage
            id="merchant::selfRegistrationForm::clickMessage"
            defaultMessage="Click "
          />
          <Link to="/dashboard/profile" aria-label="dashboard profile">
            <FormattedMessage
              id="merchant::selfRegistrationForm::configure"
              defaultMessage="Configure"
            />
          </Link>{" "}
          <FormattedMessage
            id="merchant::selfRegistrationForm::orAccessLater"
            defaultMessage="or access it later from your"
          />{" "}
          <Link to="/dashboard/profile" aria-label="dashboard profile">
            <FormattedMessage
              id="merchant::selfRegistrationForm::dashboard"
              defaultMessage="Account"
            />
          </Link>
        </p>
        <br />
        <FormattedMessage
          id="merchant::selfRegistrationForm::complete"
          defaultMessage="Please complete the setup within the next 7 days, as the Stripe link will expire after that."
          tagName="p"
        />
      </div>
      <br />
      <div className="button-wrapper">
        <Button
          type="button"
          color="carrot"
          onClick={handleEmbedded}
          disabled={loading || !merchantId}>
          <FormattedMessage
            id="merchant::selfRegistrationForm::configureStripe"
            defaultMessage="configure stripe"
          />
        </Button>
      </div>
    </>
  )
}

export default StripePage
