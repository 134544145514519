import React from "react"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { Money, Date, Button, ClickToCopy } from "@tmu/components/common"
import { FormattedMessage } from "gatsby-plugin-intl"
import {
  StyledBottomContainer,
  StyledButtonContainer,
  StyledDonationAmount,
  StyledDonationId,
  StyledDonor,
  StyledHeader,
  StyledLabel,
  StyledLine,
  StyledPageWrapper,
  StyledRow,
  StyledValue,
} from "./index.styles"
import theme from "@tmu/global/theme"
import { useIntl } from "gatsby-plugin-intl"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/pro-solid-svg-icons/faDownload"
import { faCopy } from "@fortawesome/pro-light-svg-icons/faCopy"
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons/faRotateRight"

export const DonationDetail = ({ donation, rowData }) => {
  const { formatMessage } = useIntl()
  const tableData = rowData?.original
  const donorName = tableData?.name

  const voucherText = formatMessage({
    id: "orders::table::Voucher",
    defaultMessage: "Voucher",
  })

  const useBeforeText = formatMessage({
    id: "purchase::success::useBefore2",
    defaultMessage: "Use Before",
  })

  return (
    <StyledPageWrapper>
      <StyledHeader>
        <FormattedMessage
          id="dashboard::donationDetail::title"
          defaultMessage="Donation"
        />
      </StyledHeader>
      <Spacer top={1.5} />
      <StyledDonationAmount>
        <Money value={donation?.amount} />
      </StyledDonationAmount>
      <Spacer top={1} />
      <StyledDonor>
        {tableData?.campaignName}{" "}
        {donation?.type === "SUBSCRIPTION" ? (
          <FontAwesomeIcon className="icon" icon={faRotateRight} />
        ) : null}{" "}
        {donorName?.length > 0 && " - " + donorName}
      </StyledDonor>
      <Spacer top={0.75} />
      <StyledDonationId>
        <div>{tableData?.donationId}</div>
        <div>
          <ClickToCopy text={tableData?.donationId}>
            <FontAwesomeIcon icon={faCopy} />
          </ClickToCopy>
        </div>
      </StyledDonationId>
      <Spacer bottom={1.5} />
      <StyledBottomContainer>
        <StyledRow>
          <StyledLabel>
            <FormattedMessage
              id="dashboard::tableHeader::dateAndTime"
              defaultMessage="Date / Time"
            />
          </StyledLabel>
          <StyledValue>
            <Date value={tableData?.date} showYear={true} second="numeric" />
          </StyledValue>
        </StyledRow>
        <StyledLine />
        <StyledRow>
          <StyledLabel>
            <div className="header-text header-icon align-right">
              <FormattedMessage
                id="dashboard::tableHeader::pacsReceived"
                defaultMessage="PACs Received"
              />
            </div>
          </StyledLabel>
          <StyledValue>
            <Money value={tableData?.pacs} currency={false} />
          </StyledValue>
        </StyledRow>
        {tableData?.voucherCode && (
          <>
            <StyledLine />
            <StyledRow>
              <StyledLabel>
                <div className="header-text header-icon align-right">
                  {voucherText} {tableData?.voucherCode}
                </div>
              </StyledLabel>
              <StyledValue>
                {useBeforeText?.toLowerCase()} {tableData?.endDate}
              </StyledValue>
            </StyledRow>
          </>
        )}
        <StyledLine />
      </StyledBottomContainer>
      {tableData?.receipt ? (
        <StyledButtonContainer>
          <Button
            className="blue-button"
            color={theme.colors.blue}
            onClick={() => window.open(tableData?.receipt, "_blank")}
            data-testid="btn-confirm">
            <FormattedMessage
              id="dashboard::donationDetail::downloadReceipt"
              defaultMessage="Download Receipt"
            />
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </StyledButtonContainer>
      ) : null}
    </StyledPageWrapper>
  )
}

export default DonationDetail
