import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { CustomModal } from "@tmu/components/common"

export const StopModal = ({
  isStopModalOpen,
  handleStopModalClose,
  handleEndCampaign,
  isRecurring,
  isEvent,
}) => {
  const { formatMessage } = useIntl()
  return (
    <CustomModal
      isModalOpen={isStopModalOpen}
      handleModalClose={handleStopModalClose}
      header={
        isRecurring
          ? formatMessage({
              id: "dashboard::common::sureToStopRecurringTitle",
              defaultMessage:
                "Are you sure to stop recurring donation campaign?",
            })
          : isEvent
          ? formatMessage({
              id: "dashboard::common::sureToStopEvent",
              defaultMessage: "Sure, you want to stop the event?",
            })
          : formatMessage({
              id: "dashboard::common::sureToStop",
              defaultMessage: "Sure, you want to stop the campaign?",
            })
      }
      text={
        <>
          {isRecurring &&
            formatMessage({
              id: "dashboard::common::sureToStopRecurringText",
              defaultMessage:
                "Instead of stopping your campaign. you can make it private.",
            })}
          <br />
          <br />
          {isEvent
            ? formatMessage({
                id: "dashboard::campaigns::stopCampaignWarningEvent",
                defaultMessage:
                  "Once you end an event, it cannot be reopened or get donation for this event.",
              })
            : formatMessage({
                id: "dashboard::campaigns::stopCampaignWarning",
                defaultMessage:
                  "Once you end a campaign, it cannot be reopened or get donation for this campaign.",
              })}
        </>
      }
      confirmButtonText={formatMessage({
        id: "dashboard::common::stop",
        defaultMessage: "Stop",
      })}
      confirmButtonAction={handleEndCampaign}
      confirmButtonColor="red"
      cancelButtonAction={handleStopModalClose}
      isCloseIcon={true}
      isMobile={false}
      isFull={false}
    />
  )
}
