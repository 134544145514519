import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import {
  StyledPage,
  StyledPageContent,
  StyledPageTitle,
  StyledPageSubTitle,
} from "@tmu/global/page-addons/dashboard.styles"
import { Tooltip } from "react-tooltip"
import { useApolloApiClients } from "@tmu/apollo/client"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import { useState } from "react"
import {
  Button,
  Table,
  Money,
  CustomModal,
  TextInput,
} from "@tmu/components/common"
import {
  StyledEventButtons,
  StyledBackButton,
  StyledTitleWrapper,
  StyledIconWrapper,
  StyledInfoIcon,
  StyledPageTop,
  StyledTrancatedText,
  StyledCountStatTitle,
  StyledCountStat,
  StyledCountStatsWrapper,
  StyledCountStatsItem,
  StyledEventDescription,
  StyledEventDescription2,
  StyledEventBox,
  StyledButtonBox,
} from "./index.styles"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useAuth } from "@tmu/hooks"
import { API_PERMISSIONS } from "@tmu/apollo/constants"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"
import { faPencil } from "@fortawesome/pro-solid-svg-icons/faPencil"
import { faExternalLink } from "@fortawesome/free-solid-svg-icons"
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons/faInfoCircle"
import { EVENT_DETAIL_QUERY } from "@tmu/apollo/storefront/queries/campaign"
import { useLazyQuery } from "@apollo/client"
import { UPDATE_CAMPAIGN_TIER_MUTATION } from "@tmu/apollo/dashboard/mutations/campaign"
export default function EventDetail({ dashboardType, slug }) {
  const [selectedEvent, setSelectedEvent] = useState()
  const [newTicketValue, setNewTicketValue] = useState()
  const [modalError, setModalError] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { apiPermissions } = useAuth()
  const { isDesktop, isTablet, isWide } = getAllScreenTypes()
  const { getDashboardClient } = useApolloApiClients()

  const [getCampaignDetail, { loading, data, refetch }] = useLazyQuery(
    EVENT_DETAIL_QUERY({ locale }),
    {
      client: getDashboardClient(dashboardType),
    }
  )

  const [updateCampaignTier] = useMutation(UPDATE_CAMPAIGN_TIER_MUTATION, {
    refetchQueries: [
      {
        query: EVENT_DETAIL_QUERY({ locale }),
        variables: { slug },
      },
    ],
    client: getDashboardClient(dashboardType),
  })
  const event = data?.campaign
  const eventSold = Number(event?.inventoryRecord?.booked || 0)
  const tiers =
    event?.tiers?.edges
      ?.map((item) => item?.node)
      ?.sort((a, b) => Number(a.donationAmount) - Number(b.donationAmount)) ||
    []
  useEffect(() => {
    getCampaignDetail({ variables: { slug } })
  }, [])

  const pathname = typeof window !== "undefined" ? window.location.pathname : ""
  const pathnameWithoutLocale = ["en", "it", "es"].some((locale) =>
    pathname.startsWith(`/${locale}/`)
  )
    ? pathname.replace(`/${locale}`, "")
    : pathname
  const handleEditEvent = () => {
    navigate(
      dashboardType === "donors"
        ? `/dashboard/campaign/edit/${slug}/?supporter=true&charity=${event?.partner?.slug}&type=event&backTo=${pathnameWithoutLocale}`
        : dashboardType === "merchants"
        ? `/dashboard/campaign/edit/${slug}/?supporter=false&charity=${event?.partner?.slug}&type=event&corporate=true&backTo=${pathnameWithoutLocale}`
        : dashboardType === "partners"
        ? `/dashboard/campaign/edit/${slug}/?supporter=false&charity=${event?.partner?.slug}&type=event&backTo=${pathnameWithoutLocale}`
        : ""
    )
  }

  const handleViewEvent = () => {
    // navigate(`/campaigns/${slug}`)
    window.open(`/${locale}/campaigns/${slug}`, "_blank")
  }
  const handleNavigateToEventList = () => {
    dashboardType === "donors"
      ? navigate("/dashboard/user/events/")
      : dashboardType === "merchants"
      ? navigate("/dashboard/merchants/events/")
      : dashboardType === "partners"
      ? navigate("/dashboard/charities/events/")
      : "/dashboard/profile/"
  }

  const handleUpdateTierOption = () => {
    if (newTicketValue > selectedEvent?.booked && newTicketValue <= 9999) {
      setModalError()
      updateCampaignTier({
        variables: {
          input: {
            id: selectedEvent?.id,
            maxTickets: Number(newTicketValue || 0),
            isUnlimitedTickets: false,
            description: selectedEvent?.description,
            donationAmount: selectedEvent?.donationAmount,
            donationAmountOperator: selectedEvent?.donationAmountOperator,
          },
        },
      })
      setModalOpen(false)
    } else
      setModalError(
        formatMessage(
          {
            id: "event::ticket::ticketsValidationError",
            defaultMessage: "The value must be between {no} and {max}.",
          },
          { no: Number(selectedEvent?.booked || 0) + 1, max: 9999 }
        )
      )
  }

  const openEventText = (
    <FormattedMessage
      id="dashboard::events::openEvent"
      defaultMessage="Open event"
    />
  )
  const editEventText = (
    <FormattedMessage
      id="dashboard::events::editEventText"
      defaultMessage="Edit event"
    />
  )
  const backToEventsText = (
    <FormattedMessage
      id="dashboard::events::backToEvent"
      defaultMessage="Back to events"
    />
  )

  const columns = [
    {
      Header: () => (
        <div>
          {formatMessage({
            id: "dashboard::tableHeader::ticketName",
            defaultMessage: "Ticket name",
          })}
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          <StyledTrancatedText>
            {row?.original?.description}
          </StyledTrancatedText>
        </div>
      ),
      accessor: "name",
      disableSort: true,
      headerStyle: {
        width: isWide ? "22rem" : isTablet ? "12rem" : "11rem",
      },
    },
    {
      Header: () => (
        <div>
          {formatMessage({
            id: "dashboard::tableHeader::price",
            defaultMessage: "Price",
          })}
        </div>
      ),
      Cell: ({ row }) => (
        <div>
          <StyledTrancatedText>
            <Money
              value={row?.original?.donationAmount || 0}
              maximumFractionDigits={0}
              currency={true}
            />
          </StyledTrancatedText>
        </div>
      ),
      accessor: "price",
      disableSort: true,
      headerStyle: {
        width: isWide ? "10rem" : isTablet ? "6rem" : "5rem",
      },
    },
    {
      Header: () => (
        <div className="event-center">
          <FormattedMessage
            id="dashboard::tableHeader::soldLimit"
            defaultMessage="Sold / limit"
          />
        </div>
      ),
      Cell: ({ row }) => {
        const inventoryRecord = row?.original
        const maxTickets = inventoryRecord?.isUnlimitedTickets
          ? "∞"
          : inventoryRecord?.maxTickets || 0
        const soldTickets = Number(inventoryRecord?.booked || 0)
        return (
          <>
            <div
              data-tooltip-id="info-sold"
              className="event-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedEvent(row?.original)
                setModalOpen(true)
              }}>
              {`${soldTickets}/${maxTickets}`}
            </div>
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              type="info"
              id="info-sold"
              place="bottom"
              opacity={1}
              data-html={true}
              insecure={true}
              multiline={true}
              className="tooltip">
              <FormattedMessage
                id="dashboard::events::editTicketInfoText"
                defaultMessage="Edit the amount of available tickets"
              />
            </Tooltip>
          </>
        )
      },
      accessor: "inventoryRecord",
      disableSort: true,
      headerStyle: {
        minWidth: isWide ? "12rem" : "3rem",
        maxWidth: isWide ? "12rem" : "4em",
        width: isWide ? "12rem" : "5rem",
      },
    },
    {
      Header: () => (
        <div className="event-center">
          <FormattedMessage
            id="dashboard::tableHeader::left"
            defaultMessage="Left"
          />
        </div>
      ),
      Cell: ({ row }) => (
        <div className={"event-center"}>
          {row?.original?.isUnlimitedTickets
            ? "∞"
            : row?.original?.stockLeft || 0}
        </div>
      ),
      accessor: "stockLeft",
      disableSort: true,
      headerStyle: {
        minWidth: isDesktop ? "8rem" : isTablet ? "8rem" : "3rem",
        maxWidth: isDesktop ? "8rem" : isTablet ? "8rem" : "4rem",
        width: isDesktop ? "8rem" : isTablet ? "8rem" : "5rem",
      },
    },
    {
      Header: () => (
        <div className="event-center">
          <StyledIconWrapper>
            <FormattedMessage
              id="dashboard::tableHeader::voucherUsed"
              defaultMessage="Voucher Used"
            />
            <StyledInfoIcon
              data-tooltip-id="info-voucher"
              data-tooltip="voucher">
              <FontAwesomeIcon icon={faInfoCircle} />
            </StyledInfoIcon>
            <Tooltip
              arrowColor="transparent"
              effect="solid"
              type="info"
              id="info-voucher"
              place="bottom"
              opacity={1}
              data-html={true}
              insecure={true}
              multiline={true}
              className="tooltip">
              <FormattedMessage
                id="dashboard::tableHeader::voucherUsedInfoText"
                defaultMessage="Voucher Used Info"
              />
            </Tooltip>
          </StyledIconWrapper>
        </div>
      ),
      Cell: ({ row }) => {
        return <div className={"event-center"}>{row?.original?.used || 0}</div>
      },
      accessor: "voucher",
      disableSort: true,
      headerStyle: {
        minWidth: isDesktop ? "8rem" : isTablet ? "8rem" : "3rem",
        maxWidth: isDesktop ? "8rem" : isTablet ? "8rem" : "3rem",
        width: isDesktop ? "8rem" : isTablet ? "8rem" : "3rem",
      },
    },
  ]

  return (
    <>
      <StyledPage>
        <StyledPageTop>
          <StyledBackButton
            onClick={handleNavigateToEventList}
            carrot={dashboardType === "merchants"}>
            <FontAwesomeIcon icon={faArrowLeft} />
            {backToEventsText}
          </StyledBackButton>

          <div>
            <StyledEventButtons carrot={dashboardType === "merchants"}>
              {isTablet && (
                <div onClick={handleEditEvent}>
                  <FontAwesomeIcon icon={faPencil} />
                  {editEventText}
                </div>
              )}
              <div onClick={handleViewEvent}>
                <FontAwesomeIcon icon={faExternalLink} />
                {openEventText}
              </div>
            </StyledEventButtons>
          </div>
        </StyledPageTop>

        <StyledPageTitle>
          <div>
            <h1>{event?.name}</h1>
          </div>
        </StyledPageTitle>

        <StyledPageSubTitle>
          <div>
            <FormattedMessage
              id="dashboard::events::analytics"
              defaultMessage="Analytics"
              tagName="h6"
            />
          </div>
        </StyledPageSubTitle>
        <StyledCountStatsWrapper>
          <StyledCountStatsItem>
            <StyledCountStat>
              <Money
                value={eventSold}
                maximumFractionDigits={0}
                currency={false}
              />
            </StyledCountStat>
            <StyledCountStatTitle className="caption">
              <FormattedMessage
                id="event::ticket::sold"
                defaultMessage="Sold"
              />
            </StyledCountStatTitle>
          </StyledCountStatsItem>
          <StyledCountStatsItem>
            <StyledCountStat>
              <Money
                value={event?.fundedAmount || 0}
                maximumFractionDigits={0}
                currency={true}
              />
            </StyledCountStat>
            <StyledCountStatTitle className="caption">
              <FormattedMessage
                id="event::ticket::revenue"
                defaultMessage="Revenue"
              />
            </StyledCountStatTitle>
          </StyledCountStatsItem>
        </StyledCountStatsWrapper>
        <StyledPageSubTitle>
          <div>
            <FormattedMessage
              id="dashboard::events::ticketOptions"
              defaultMessage="Ticket options"
              tagName="h6"
            />
          </div>
        </StyledPageSubTitle>

        <StyledPageContent data-testid="events-page-content">
          <Table
            columns={columns}
            data={tiers}
            hiddenColumns={[]}
            options={{ sort: false }}
          />
          <br />
        </StyledPageContent>

        {apiPermissions?.includes(API_PERMISSIONS.MERCHANT_CREATE_PRODUCT) &&
          !isTablet && (
            <div className="sticky-bottom-bar">
              <Button
                data-testid="btn-create-event"
                color="transparent"
                type="button"
                onClick={handleEditEvent}>
                {editEventText}
              </Button>
            </div>
          )}
      </StyledPage>
      {isModalOpen ? (
        <CustomModal
          isOpen={isModalOpen}
          style={{ maxWidth: 400 }}
          header={
            isTablet && (
              <FormattedMessage
                id="dashboard::events::ticketOptionsPopupHeader"
                defaultMessage="Ticket limit update"
              />
            )
          }
          text={
            !isTablet && (
              <FormattedMessage
                id="dashboard::events::ticketOptionsPopupHeader"
                defaultMessage="Ticket limit update"
              />
            )
          }
          isCloseIcon={true}
          isCloseXButton={isTablet ? true : false}
          isCloseBackButton={!isTablet ? true : false}
          cancelButtonAction={() => setModalOpen(false)}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          isMobile={!isTablet}
          isFull={!isTablet}>
          <StyledEventDescription>
            {selectedEvent?.description}
          </StyledEventDescription>
          <StyledEventDescription2>
            <div>
              {selectedEvent?.booked}{" "}
              <FormattedMessage
                id="event::ticket::sold"
                defaultMessage="Sold"
              />
            </div>
            <div>
              {formatMessage(
                {
                  id: "event::ticket::ticketsLeft",
                  defaultMessage: "{no} Tickets left",
                },
                {
                  no: selectedEvent?.stockLeft,
                }
              )}
            </div>
          </StyledEventDescription2>
          <StyledEventBox>
            <TextInput
              newDesign
              data-testid="input-number"
              id="number"
              name="number"
              type="number"
              onChange={(e) => setNewTicketValue(e.target.value)}
              value={newTicketValue}
              label={formatMessage({
                id: "event::ticket::ticketLimit",
                defaultMessage: "Ticket limit",
              })}
              // placeholder={formatMessage({
              //   id: "signUp::password",
              //   defaultMessage: "Password",
              // })}
              error={modalError}
              touched={true}
            />
          </StyledEventBox>
          <StyledButtonBox>
            <div className={isTablet ? "" : "sticky-bottom-bar"}>
              <Button
                data-testid="btn-save"
                className="blue-button"
                color={dashboardType === "merchants" ? "carrot" : "blue"}
                type="button"
                onClick={handleUpdateTierOption}>
                {formatMessage({
                  id: "event::ticket::update",
                  defaultMessage: "Update",
                })}
              </Button>
            </div>
          </StyledButtonBox>
        </CustomModal>
      ) : null}
    </>
  )
}
