import React from "react"
import EventListing from "./EventListing"
import EventDetail from "./EventDetail"
import { Router } from "@reach/router"
import OfferTypes from "../OfferTypes"

export default function Services({ dashboardType }) {
  return (
    <Router>
      <EventListing path="/" dashboardType={dashboardType} />
      <EventDetail path="/detail/:slug" dashboardType={dashboardType} />
      <OfferTypes path="/type" />
    </Router>
  )
}
