import styled from "@emotion/styled"

export const StyledEventButtons = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    font-family: ${(p) => p.theme.fontFamilies.regular};
    font-weight: ${(p) => p.theme.fontWeights.regular};
    color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
    font-size: 1rem;
    margin-left: 1.5rem;
    svg {
      color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
      height: 0.8rem;
      margin-right: 0.3rem;
    }
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    div {
      font-size: 1.5rem;
      svg {
        height: 1.3rem;
      }
    }
  }
`
export const StyledTrancatedText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    max-width: 11rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.desktop}) {
    max-width: 22rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    max-width: 42rem;
  }
`

export const StyledPageTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const StyledBackButton = styled.div`
  color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
  cursor: pointer;
  svg {
    margin-right: 0.5rem;
  }
`

export const StyledInfoIcon = styled.div`
  color: ${(p) => p.theme.colors.blue};
  height: 1.25rem;
`

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack};
    overflow-wordwrap: break-word;
    width: 20rem;
    padding: 1rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    text-transform: none;
    font-weight: ${(p) => p.theme.fontWeights.regular};
  }

  svg {
    height: 0.5rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }
  align-self: top;
  margin-top: 1rem;
  margin-left: 1rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    align-self: center;
    margin-top: unset;
    svg {
      height: 0.8rem;
    }
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    svg {
      height: 1.3rem;
    }
  }
`
export const StyledTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
`
export const StyledEventDescription2 = styled.div`
display: flex;
justify-content: space-between;
 div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
    margin-bottom: 1rem;
`

export const StyledEventDescription = styled.div`
  width: 310px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  > div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }
`
export const StyledEventBox = styled.div`
  text-align: left;
  margin-bottom: 2rem;
  input {
    text-align: right;
  }
  .input-feedback-absolute {
    bottom: -2rem;
  }
`
export const StyledButtonBox = styled.div`
  button {
    width: 100%;
  }
`

export const StyledCountStatsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
`
export const StyledCountStatsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-width: 10rem;
  text-align: center;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    min-width: 18rem;
  }
`
export const StyledCountStat = styled.span`
  color: ${(p) => p.theme.colors.pitchBlack};
  font-weight: ${(p) => p.theme.fontWeights.semiBolder};
  font-family: ${(p) => p.theme.fontFamilies.headline};
  font-size: 1.5rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 3rem;
  }
`

export const StyledCountStatTitle = styled.span`
  font-size: 0.625rem;
  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 0.75rem;
  }
  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 1.25rem;
  }
`
