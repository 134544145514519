import {
  API_PERMISSIONS,
  CAMPAIGNS_TABS_MENU_SHORT,
} from "@tmu/apollo/constants"
import {
  Date as CustomDate,
  CustomModal,
  Spinner,
  Table,
  TooltipSelectMenu,
  Tabs,
  TabList,
  Tab,
  NewCard,
} from "@tmu/components/common"
import { EVENT_LISTING_QUERY } from "@tmu/apollo/storefront/queries/merchant"
import { CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE } from "@tmu/apollo/dashboard/mutations/campaign"
import { VISIBILITY_STATUS, REVIEW_STATUS } from "@tmu/apollo/constants"
import {
  END_CAMPAIGN_MUTATION,
  DELETE_CAMPAIGN_MUTATION,
} from "@tmu/apollo/dashboard/mutations/campaign"
import Money from "@tmu/components/common/Money"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { Spacer } from "@tmu/global/page-addons/detail-page.styles"
import { useAuth, useDefaultMerchant, useToast } from "@tmu/hooks"
import { HomePageContext } from "@tmu/src/context/homePageContext"
import { format } from "date-fns"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-intl"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"
import { getQueryParams, getValueForLocale } from "@tmu/utils/string"
import { CARDS_PER_PAGE, LIST_TYPES } from "@tmu/apollo/constants"
import { OFFER_TYPE } from "@tmu/apollo/constants"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import React, { useContext, useEffect, useRef, useState } from "react"
import {
  StyledSimpleTextContent,
  StyledUsedCardTitle,
} from "../../dashboardPages/Profile/index.styles"
import {
  StyledLeftStockItem,
  StyledPageWrapper,
  StyledEventNameWrapper,
  StyledTable,
  StyledTabs,
  StyledStatusWrapper,
} from "./index.styles"
import { useApolloApiClients } from "@tmu/apollo/client"

const getSortValue = (sortBy) => {
  const sortData = sortBy?.at(0)
  const direction = sortData?.desc === true ? "-" : ""
  let sortField = null

  switch (sortData?.id) {
    case "date":
      sortField = "created"
      break
    default:
      sortField = sortData?.id
      break
  }
  return sortField ? direction + sortField : null
}
const stableDate = new Date().toISOString().split("T")[0] + "T00:00:00.000Z"

const EventsTable = ({ onRowSelect, dashboardType }) => {
  const { storefrontClient, getDashboardClient } = useApolloApiClients()
  const { locale, defaultLocale, formatMessage } = useIntl()
  const { isLoading, user, apiPermissions } = useAuth()
  const [tabIndex, setTabIndex] = useState(0)
  const { defaultMerchant, isMerchantCorporate, isMerchantOffline } =
    useDefaultMerchant()
  const { isTablet, isWide, isDesktop } = getAllScreenTypes()
  const [pageSize, setPageSize] = useState(10)
  const { setGlobalSpinnerStatus } = useContext(HomePageContext)
  const [offset, setOffset] = useState(0)
  const tabMenu = CAMPAIGNS_TABS_MENU_SHORT(formatMessage)

  const { success, error, info } = useToast()
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])

  const selectMenuRef = useRef()
  const [menuStatus, setMenuStatus] = useState({})
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [isEndModalOpen, setEndModalOpen] = useState(false)

  const getVariables = () => {
    if (!user?.id || user?.id.length === 0 || isLoading) {
      return
    }

    const variables = {
      first: pageSize,
      orderBy: getSortValue(sortInfo),
      fundraiserType: ["events"],
      // visibilityStatus: ["1", "2", "3"],
      offset: offset * pageSize,
    }
    if (dashboardType === "donors") {
      variables.user = user?.id
    }
    return tabIndex === 0
      ? {
          ...variables,
          status_In: ["APPROVED", "WAITING_FOR_APPROVAL", "IN_PROGRESS"],

          eventStartsAt_Gte: stableDate,
        }
      : // : tabIndex === 1
        //   ? {
        //     ...variables,
        //     status_In: ["ENDED"],
        //   }
        //   : tabIndex === 2
        //     ? {
        //       ...variables,
        //     }
        {
          ...variables,
          status_In: [
            "ENDED",
            "APPROVED",
            "WAITING_FOR_APPROVAL",
            "IN_PROGRESS",
          ],
        }
  }

  const { loading, data, refetch } = useQuery(
    EVENT_LISTING_QUERY({ isPartner: dashboardType === "partners" }),
    {
      client: getDashboardClient(dashboardType),
      variables: getVariables(),
      fetchPolicy: "network-only",
    }
  )

  const [partialUpdateCampaign] = useMutation(
    CAMPAIGN_SUBMIT_FOR_PARTIAL_UPDATE,
    {
      client: getDashboardClient(dashboardType),
      refetchQueries: [
        {
          query: EVENT_LISTING_QUERY({
            isPartner: dashboardType === "partners",
          }),
          client: getDashboardClient(dashboardType),
          variables: getVariables(),
          fetchPolicy: "network-only",
        },
      ],
    }
  )

  const [deleteCampaign] = useMutation(DELETE_CAMPAIGN_MUTATION, {
    client: getDashboardClient(dashboardType),
    refetchQueries: [
      {
        query: EVENT_LISTING_QUERY({ isPartner: dashboardType === "partners" }),
        client: getDashboardClient(dashboardType),
        variables: getVariables(),
        fetchPolicy: "network-only",
      },
    ],
  })
  const [endCampaign] = useMutation(END_CAMPAIGN_MUTATION, {
    client: getDashboardClient(dashboardType),
    refetchQueries: [
      {
        query: EVENT_LISTING_QUERY({ isPartner: dashboardType === "partners" }),
        client: getDashboardClient(dashboardType),
        variables: getVariables(),
        fetchPolicy: "network-only",
      },
    ],
  })

  // const [dublicateEvent] = useMutation(CREATE_PARTNER_CAMPAIGN_MUTATION, {
  //   client: getDashboardClient(dashboardType),
  // })

  const campaignsTotalCount = data?.allCampaignsTotalCount?.totalCount

  const campaigns = data?.allCampaigns?.edges?.filter(
    (item) => !item?.node?.isDefaultCampaign
  )

  const hasCampaigns = campaigns?.length > 0

  const hasAllCampaigns = data?.allCampaignsTotalCount?.totalCount > 0

  const tabs = tabMenu

  const merchantUsers = user?.merchantUsers?.edges

  const activeMerchantTemp =
    merchantUsers?.length === 1
      ? merchantUsers?.[0]
      : merchantUsers?.find(
          (merchant) =>
            merchant?.node?.store?.id === user?.activeStore?.id ||
            merchant?.node?.store?.id === defaultMerchant?.id
        )

  const permission = (type) =>
    apiPermissions?.includes(type) ||
    activeMerchantTemp?.node?.finalPermissions?.includes(type)

  const tableData = !campaigns
    ? []
    : campaigns
        ?.filter((item) => !item?.node?.isDefaultCampaign)
        ?.map((item) => {
          return {
            id: item?.node?.id,
            name: item?.node?.name,
            eventStartsAt: item?.node?.eventStartsAt,
            startsAt: item?.node?.startsAt,
            visibilityStatus: item?.node?.visibilityStatus,
            slug: item?.node?.slug,
            fundedAmount: item?.node?.fundedAmount,
            statusCode: item?.node?.status,
            inventoryRecord: item?.node?.inventoryRecord,
            status: formatMessage({
              id: `reviewStatus::type::${item?.node?.status}`,
              defaultMessage: "-",
            }),
          }
        })

  const handleNavigateToEventDetail = (slug) => {
    dashboardType === "donors"
      ? navigate(`/dashboard/user/events/detail/${slug}`)
      : dashboardType === "merchants"
      ? navigate(`/dashboard/merchants/events/detail/${slug}`)
      : dashboardType === "partners"
      ? navigate(`/dashboard/charities/events/detail/${slug}`)
      : "/dashboard/profile/"
  }

  const columns = [
    {
      Header: () => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::event"
            defaultMessage="Event"
          />
        </div>
      ),
      Cell: (props) => (
        <StyledEventNameWrapper
          carrot={dashboardType === "merchants"}
          onClick={() =>
            handleNavigateToEventDetail(props?.row?.original?.slug)
          }>
          {props?.value}
        </StyledEventNameWrapper>
      ),
      accessor: "name",
      disableSort: true,
      headerStyle: {
        maxWidth: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        minWidth: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        width: isWide ? "22rem" : isDesktop ? "12rem" : "8.5rem",
        pointerEvents: "none",
      },
    },
    {
      Header: () => (
        <div className="header-text header-icon">
          <FormattedMessage
            id="dashboard::tableHeader::status"
            defaultMessage="Status"
          />
        </div>
      ),
      Cell: ({ row }) => {
        return (
          <StyledStatusWrapper>
            <FontAwesomeIcon
              icon={
                row.original.visibilityStatus === VISIBILITY_STATUS.UNLISTED
                  ? faEyeSlash
                  : faEye
              }
              className="input-icon"
            />
            {row.original.status}
          </StyledStatusWrapper>
        )
      },
      accessor: "status",
      disableSort: true,
      headerStyle: {
        maxWidth: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
        minWidth: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
        width: isWide ? "9rem" : isDesktop ? "7rem" : "7rem",
        pointerEvents: "none",
      },
    },
    {
      Header: () => (
        <div className="header-text align-left">
          <FormattedMessage
            id="dashboard::tableHeader::eventDate"
            defaultMessage="Event date"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value && new Date(value) instanceof Date
          ? format(new Date(value), "MMM dd, yyyy, HH:mm:ss")
          : null,
      accessor: "eventStartsAt",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        minWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        width: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
      },
    },
    {
      Header: () => (
        <div className="header-text align-center">
          <FormattedMessage
            id="dashboard::tableHeader::soldLimit"
            defaultMessage="Sold / limit"
          />
        </div>
      ),
      Cell: ({ row }) => {
        const inventoryRecord = row?.original?.inventoryRecord
        const maxTickets = inventoryRecord?.isUnlimitedTickets
          ? "∞"
          : inventoryRecord?.maxTickets
        const soldTickets = Number(inventoryRecord?.booked || 0)
        return (
          <StyledLeftStockItem className="align-center">{`${soldTickets}/${maxTickets}`}</StyledLeftStockItem>
        )
      },
      accessor: "inventoryRecord",
      disableSort: true,
      headerStyle: {
        maxWidth: isWide ? "9rem" : isDesktop ? "6.5rem" : "5.5rem",
        minWidth: isWide ? "9rem" : isDesktop ? "6.5rem" : "5.5rem",
        width: isWide ? "9rem" : isDesktop ? "6.5rem" : "5.5rem",
        pointerEvents: "none",
      },
    },
    {
      Header: () => (
        <div className="header-text">
          <FormattedMessage
            id="dashboard::tableHeader::publishingDate"
            defaultMessage="Publishing date"
          />
        </div>
      ),
      Cell: ({ value }) =>
        value && new Date(value) instanceof Date ? (
          format(new Date(value), "MMM dd, yyyy, HH:mm:ss")
        ) : (
          <span className="symbol">∞</span>
        ),
      accessor: "startsAt",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        minWidth: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
        width: isWide ? "12rem" : isDesktop ? "7rem" : "6rem",
      },
    },
    {
      Header: () => (
        <div className="header-text header-icon align-center">
          <FormattedMessage
            id="dashboard::tableHeader::revenue"
            defaultMessage="Revenue"
          />
        </div>
      ),
      Cell: ({ value, row }) => {
        const fromText =
          row?.original?.productVariation?.length > 1
            ? formatMessage({
                id: "dashboard::tableHeader::from",
                defaultMessage: "from",
              })
            : ""
        return (
          <div className="align-center price-cell">
            {value && (
              <>
                {fromText} <Money value={value} />
              </>
            )}
          </div>
        )
      },
      accessor: "fundedAmount",
      disableSort: false,
      headerStyle: {
        maxWidth: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
        minWidth: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
        width: isWide ? "9rem" : isDesktop ? "6rem" : "5rem",
      },
    },
    {
      Cell: ({ row }) => {
        const { index, original } = row
        const event = original
        const visibilityStatus = event?.visibilityStatus
        const statusCode = event?.statusCode
        return (
          <TooltipSelectMenu
            ref={selectMenuRef}
            menuIsOpen={menuStatus[index]}
            row={row}
            menuWidth={menuWidth}
            tooltipHeight={tooltipHeight}
            options={getMenuOptions(visibilityStatus, statusCode)}
            onMouseLeave={() => {
              const temp = {}
              temp[index] = 0
              setMenuStatus({ ...menuStatus, ...temp })
            }}
            onMouseOver={() => {
              if (menuStatus[index] === 1) {
                return
              }
              const temp = {}
              temp[index] = 1
              Object.keys(menuStatus)?.forEach((ind) => {
                if (ind != index) {
                  temp[ind] = 0
                }
              })
              setMenuStatus({ ...menuStatus, ...temp })
            }}
          />
        )
      },
      id: "edit",
      disableSort: true,
    },
  ]

  const hiddenColumns = isDesktop ? [] : ["startsAt"]

  const handleRowClick = (row, index) => {
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    console.log("sortBy", sortBy)
    setSortInfo(sortBy)
  }

  const menuItemCancel = {
    value: "cancel",
    label: formatMessage({
      id: "eventTable::rowMenu::cancelEvent",
      defaultMessage: "Cancel event",
    }),
    action: (row) => {
      setEndModalOpen(true)
      setSelectedRow(row)
      setMenuStatus({})
    },
  }

  const menuItemUnlist = {
    value: "unlist",
    label: formatMessage({
      id: "eventTable::rowMenu::unlist",
      defaultMessage: "Unlist",
    }),
    action: (row) => {
      partialUpdateCampaign({
        variables: {
          input: {
            visibilityStatus: VISIBILITY_STATUS.UNLISTED,
            id: row?.original?.id,
          },
        },
      })
    },
  }

  const menuItemList = {
    value: "list",
    label: formatMessage({
      id: "eventTable::rowMenu::list",
      defaultMessage: "List",
    }),
    action: (row) => {
      partialUpdateCampaign({
        variables: {
          input: {
            visibilityStatus: VISIBILITY_STATUS.LISTED,
            id: row?.original?.id,
          },
        },
      })
    },
  }

  const getMenuOptions = (visibilityStatus, statusCode) => {
    const otherOptions = []

    if (visibilityStatus === VISIBILITY_STATUS.LISTED) {
      otherOptions.push(menuItemUnlist)
    } else if (visibilityStatus === VISIBILITY_STATUS.UNLISTED) {
      otherOptions.push(menuItemList)
    }
    // if (visibilityStatus !== VISIBILITY_STATUS.ARCHIVED) {
    //   otherOptions.push(menuItemArchive)
    // }
    if (statusCode !== REVIEW_STATUS.ENDED) {
      otherOptions.push(menuItemCancel)
    }

    const options = [
      {
        value: "edit",
        label: formatMessage({
          id: "eventTable::rowMenu::editEvent",
          defaultMessage: "Edit Event",
        }),
        action: (row) => {
          navigate(
            dashboardType === "donors"
              ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=true&charity=${row?.original?.partner?.slug}&type=event`
              : dashboardType === "merchants"
              ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=false&charity=${row?.original?.partner?.slug}&type=event&corporate=true`
              : dashboardType === "partners"
              ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=false&charity=${row?.original?.partner?.slug}&type=event`
              : ""`/dashboard/merchants/events/edit?id=${row?.original?.id}`
          )
        },
      },
      ...otherOptions,
      // {
      //   value: "dublicate",
      //   label: formatMessage({
      //     id: "eventTable::rowMenu::duplicate",
      //     defaultMessage: "Duplicate Event",
      //   }),
      //   action: (row) => {
      // dublicateEvent().then((data) => {
      //   console.log("data", data)
      // navigate(
      //   dashboardType === "donors"
      //     ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=true&charity=${row?.original?.partner?.slug}&type=event`
      //     : dashboardType === "merchants"
      //     ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=false&charity=${row?.original?.partner?.slug}&type=event&corporate=true`
      //     : dashboardType === "partners"
      //     ? `/dashboard/campaign/edit/${row?.original?.slug}/?supporter=false&charity=${row?.original?.partner?.slug}&type=event`
      //     : ""`/dashboard/merchants/events/edit?id=${row?.original?.id}`
      // )
      // })
      //   },
      // },
      {
        value: "delete",
        label: formatMessage({
          id: "eventTable::rowMenu::delete",
          defaultMessage: "Delete",
        }),
        action: (row) => {
          setDeleteModalOpen(true)
          setSelectedRow(row)
          setMenuStatus({})
        },
      },
      {
        value: "copyLinkToTheEvent",
        label: formatMessage({
          id: "eventTable::rowMenu::copyLinkToTheEvent",
          defaultMessage: "Copy link to the event",
        }),
        action: (row) => {
          const port = window.location.port
          // const portStr = port?.length > 0 ? `:${port}` : port
          const eventUrl = `${window.location.origin}/${locale}/campaigns/${row?.original?.slug}`
          navigator.clipboard.writeText(eventUrl)
          const copiedMessage = formatMessage({
            id: "ui::copiedToClipboard",
            defaultMessage: "Copied to clipboard!",
          })
          success(copiedMessage)
        },
      },
      {
        value: "viewEventPage",
        label: formatMessage({
          id: "eventTable::rowMenu::viewEventPage",
          defaultMessage: "View event page",
        }),
        action: (row) => {
          navigate(`/campaigns/${row?.original?.slug}`)
        },
      },
    ]

    return options
  }

  const menuWidth = isWide ? 220 : 160
  const tooltipHeight = 190

  const handleConfirmEnd = () => {
    endCampaign({
      variables: { input: { id: selectedRow?.original?.id } },
    })
      .then(({ data, errors }) => {
        if (errors?.length) errors.map((err) => error(err.message))
        if (data.endCampaign?.errors?.length)
          data.endCampaign?.errors.map((err) => error(err.messages?.[0]))
        if (data.endCampaign?.success) {
          success(
            formatMessage({
              id: "dashboard::campaignList::eventEnded",
              defaultMessage: "The event is ended successfully",
            })
          )
        }
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        setGlobalSpinnerStatus(false)
        refetch()
      })
  }

  const handleConfirmDelete = () => {
    deleteCampaign({
      variables: { input: { id: selectedRow?.original?.id } },
    })
      .then(({ data, errors }) => {
        if (errors?.length) errors.map((err) => error(err.message))
        if (data.deleteCampaign?.errors?.length)
          data.deleteCampaign?.errors.map((err) => error(err.messages?.[0]))
        if (data.deleteCampaign?.success) {
          success(
            formatMessage({
              id: "dashboard::campaignList::eventDeleted",
              defaultMessage: "The event is deleted successfully",
            })
          )
        }
      })
      .catch((err) => {
        error(
          err?.message ??
            formatMessage({
              id: "dashboard::stores::errorMessage",
              defaultMessage: "An error occurred",
            })
        )
      })
      .finally(() => {
        setGlobalSpinnerStatus(false)
        refetch()
      })
  }

  const handleCancelDelete = () => {
    setDeleteModalOpen(false)
    setEndModalOpen(false)
    setSelectedRow(null)
  }

  return (
    <>
      <StyledTabs>
        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            {tabs.map(({ key, label, dataTestId }) => (
              <Tab key={key} data-testid={"test-id-" + dataTestId}>
                {label}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </StyledTabs>

      {false ? (
        <Spinner condensed />
      ) : isTablet && tableData ? (
        <StyledPageWrapper>
          <StyledTable>
            <Table
              carrot={dashboardType === "merchants"}
              columns={columns}
              data={tableData}
              hiddenColumns={hiddenColumns}
              headerLineSeperator={true}
              onRowClick={!isTablet ? handleRowClick : null}
              initialSort={sortInfo}
              handleSort={handleSort}
              options={{ sort: true }}
              paginationOptions={{
                pageCount: Math.ceil(
                  ((data?.allCampaigns?.totalCount || 0) - 1) / pageSize
                ),
                handlePageClick: ({ selected }) => {
                  setOffset(selected)
                },
                handlePageSizeChange: (size) => {
                  setPageSize(size)
                },
                initialPage: offset,
                initialPageSize: pageSize,
                pageSizesLabel:
                  isTablet &&
                  formatMessage({
                    id: "eventTable::pagination::eventsOnPage",
                    defaultMessage: "Events on page",
                  }),
                isTransparent: !isTablet,
                customStyles: { bottom: !isTablet ? "5rem" : "0" },
              }}
              hasPagination={true}
            />
          </StyledTable>

          {isDeleteModalOpen && (
            <CustomModal
              isModalOpen={isDeleteModalOpen}
              style={isTablet && { textAlign: "unset" }}
              children={
                <>
                  <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
                  <Spacer bottom={isWide ? 3.5 : 1.5} />
                </>
              }
              header={
                <StyledUsedCardTitle>
                  <FormattedMessage
                    id="dashboard::events::confirmDeleteEvent"
                    defaultMessage="Are you sure to delete your event?"
                  />
                </StyledUsedCardTitle>
              }
              isCloseIcon={true}
              cancelButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalNo"
                  defaultMessage="No"
                />
              }
              cancelButtonAction={() => {
                handleCancelDelete()
              }}
              confirmButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalYes"
                  defaultMessage="Yes"
                />
              }
              confirmButtonAction={() => {
                handleConfirmDelete()
                handleCancelDelete()
              }}
              modalActionsStyle={{ display: "flex" }}
              isMobile={!isTablet}
              isFull={false}
            />
          )}
          {isEndModalOpen && (
            <CustomModal
              isModalOpen={isEndModalOpen}
              style={isTablet && { textAlign: "unset" }}
              children={
                <>
                  <StyledSimpleTextContent className="delete-account-text"></StyledSimpleTextContent>
                  <Spacer bottom={isWide ? 3.5 : 1.5} />
                </>
              }
              header={
                <StyledUsedCardTitle>
                  <FormattedMessage
                    id="dashboard::events::confirmCancelEvent"
                    defaultMessage="Are you sure to cancel your event?"
                  />
                </StyledUsedCardTitle>
              }
              isCloseIcon={true}
              cancelButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalNo"
                  defaultMessage="No"
                />
              }
              cancelButtonAction={() => {
                handleCancelDelete()
              }}
              confirmButtonText={
                <FormattedMessage
                  id="dashboard::campaigns::modalYes"
                  defaultMessage="Yes"
                />
              }
              confirmButtonAction={() => {
                handleConfirmEnd()
                handleCancelDelete()
              }}
              modalActionsStyle={{ display: "flex" }}
              isMobile={!isTablet}
              isFull={false}
            />
          )}
        </StyledPageWrapper>
      ) : campaigns?.length ? (
        campaigns?.map(({ node: campaign }) => {
          return (
            <NewCard
              className="eventCard"
              variant={campaign?.isDefaultCampaign ? "charity" : "campaign"}
              key={campaign?.id}
              id={campaign?.id}
              imageSrc={campaign?.image?.url || campaign?.image}
              title={getValueForLocale(campaign, "name", locale, defaultLocale)}
              type={campaign?.campaignType}
              totalDonors={campaign?.donationCount}
              totalSupport={campaign?.fundedAmount}
              size={LIST_TYPES.LIST}
              cardLink={
                dashboardType === "donors"
                  ? `/dashboard/user/events/detail/${campaign?.slug}`
                  : dashboardType === "merchants"
                  ? `/dashboard/merchants/events/detail/${campaign?.slug}`
                  : dashboardType === "partners"
                  ? `/dashboard/charities/events/detail/${campaign?.slug}`
                  : ""
              }
              goalAmount={campaign?.goalAmount}
              progress={campaign?.progress}
              fundedAmount={campaign?.fundedAmount}
              partnerLogo={getValueForLocale(
                campaign?.partner,
                "logo",
                locale,
                defaultLocale
              )}
              storeLogo={getValueForLocale(
                campaign?.store,
                "logo",
                locale,
                defaultLocale
              )}
              partnerName={
                campaign?.partner?.displayName || campaign?.partner?.name
              }
              taxId={campaign?.partner?.taxId}
              pacMultiplier={campaign?.pacMultiplier}
              isStoreSupporter={campaign?.store?.id ? true : false}
              isCorporate={
                campaign?.store?.defaultOffer?.offerType === OFFER_TYPE.BRAND
              }
            />
          )
        })
      ) : (
        <EmptyTable
          emptyMessage={
            <FormattedMessage
              id="dashboard::partner::events::empty"
              defaultMessage="It seems no event yet"
            />
          }
        />
      )}
    </>
  )
}

export default EventsTable
