/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, keywords, title, image, pathname, lang }) {
  const { formatMessage } = useIntl()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            fbAppId
          }
        }
      }
    `
  )

  const pageTitle = formatMessage({
    id: "seo::common::pageTitle",
    defaultMessage: "Donate, Fundraise and Shop",
  })
  const metaTitle = `${title} ${title ? "|" : ""} ${
    site.siteMetadata.title
  } | ${pageTitle}`
  const metaDescription =
    description?.slice(0, 160) || site.siteMetadata.description
  const metaImageSrc = image
  const metaImageWidth = 600
  const metaImageHeight = 317
  const canonical = pathname
    ? `${site.siteMetadata.siteUrl}${pathname || ""}`
    : null
  const canonicalUrl =
    canonical?.charAt(canonical.length - 1) === "/"
      ? canonical
      : `${canonical}/`
  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonicalUrl,
              },
              {
                property: `og:url`,
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "en-gb",
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "en-us",
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "en",
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "it",
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "es",
                href: canonicalUrl,
              },
              {
                rel: "alternate",
                hrefLang: "x-default",
                href: canonicalUrl,
              },
            ]
          : []
      }
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "keywords",
          content: keywords.join(","),
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.fbAppId,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: canonicalUrl,
        },
        {
          property: "og:image",
          content: metaImageSrc,
        },
        {
          name: `og:url`,
          content: canonicalUrl,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:card",
          content: "summary",
        },
        {
          name: "twitter:image",
          content: metaImageSrc,
        },
        {
          name: "twitter:image:alt",
          content: metaTitle,
        },
        {
          name: "description",
          property: "og:description",
          content: metaDescription,
        },
      ]
        .concat(
          image
            ? [
                {
                  property: `og:image`,
                  content: metaImageSrc,
                },
                {
                  property: `og:site_name`,
                  content: site.siteMetadata.title,
                },
                {
                  property: `og:image:alt`,
                  content: metaTitle,
                },
                {
                  property: `og:locale`,
                  content: "en_US",
                },
                {
                  property: `og:locale:alternate`,
                  content: "en",
                },
                {
                  property: `og:locale:alternate`,
                  content: "es_ES",
                },
                {
                  property: `og:locale:alternate`,
                  content: "it_IT",
                },
                {
                  property: "og:image:width",
                  content: metaImageWidth,
                },
                {
                  property: "og:image:height",
                  content: metaImageHeight,
                },
                {
                  property: `twitter:image`,
                  content: metaImageSrc,
                },
                {
                  name: "twitter:image:alt",
                  content: metaTitle,
                },
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ]
            : [
                {
                  name: `twitter:card`,
                  content: `summary`,
                },
              ]
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  title: ``,
  description: ``,
  keywords: [],
  lang: `en`,
  meta: [],
  pathname: ``,
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
