import styled from "@emotion/styled"

export const StyledMainText = styled.div`
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-family: ${(p) => p.theme.fontFamilies.headlineBig};
  font-style: ${(p) => p.theme.fontStyles.normal};
  font-weight: ${(p) => p.theme.fontWeights.liteBlack};
  color: ${(p) => p.theme.colors.pitchBlack};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  span {
    color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
  }
  hr {
    margin: 1.25rem 0 0.75rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    font-size: 1rem;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
    font-size: 2rem;
  }
`
export const StyledCreate = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    font-family: ${(p) => p.theme.fontFamilies.headline};
    font-weight: ${(p) => p.theme.fontWeights.semiBolder};
    color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
    font-size: 1rem;
    line-height: 1.25rem;
    text-transform: uppercase;
    height: 1.25rem;
    svg {
      color: ${(p) => (p.carrot ? p.theme.colors.carrot : p.theme.colors.blue)};
      height: 1.25rem;
    }
  }
`
