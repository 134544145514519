import { capitalize } from "@tmu/utils/string"

export const campaignListingParts = `
  fragment campaignListingParts on CampaignNode {
    id
    slug
    status
    nameEn
    nameIt
    nameEs
    name
    image {
      url
      cache
    }
    images {
      edges {
        node {
          id
          image
          order
        }
      }
    }
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    isDefaultCampaign
    isAvailable
    deadline
    startsAt
    goalAmount
    fundedAmount
    donationCount
    isFeatured
    isUnlisted
    isListed
    visibilityStatus
    campaignType
    progress
    pacMultiplier
    __typename
  }
`

export const campaignListingPartsWithLocale = (locale) => {
  const localeSuffix = capitalize(locale)
  return `fragment campaignListingParts on CampaignNode {
    id
    slug
    status
    name${localeSuffix}
    name
    image {
      url
      cache
    }
    shortDescription${localeSuffix}
    shortDescription
    isDefaultCampaign
    isAvailable
    deadline
    startsAt
    goalAmount
    fundedAmount
    donationCount
    isFeatured
    isUnlisted
    isListed
    visibilityStatus
    campaignType
    progress
    pacMultiplier
    __typename
  }
`
}

export const campaignDetailParts = `
  fragment campaignDetailParts on CampaignNode {
    id
    slug
    status
    name
    nameEn
    nameIt
    nameEs
    image {
      url
      cache
    }
    stripePublicKey
    descriptionEn
    descriptionIt
    descriptionEs
    isAvailable
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    isVoucher
    isDefaultCampaign
     supportedCampaign{
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
    campaignType
    video
    deadline
    goalAmount
    fundedAmount
    donationCount
    isFeatured
    startsAt
    isListed
    isUnlisted
    enableDonationNote
    progress
    eventAddress
    eventMapLink
    virtualEventLink
    eventStartsAt
    pacMultiplier
    supporterCause {
      id
      name
      nameEn
      nameIt
      nameEs
      nameDe
      icon
      slug
    }
    tiers {
      edges {
        node {
          id,
          campaign {
            id
            name
          },
          description,
          donationAmount,
          donationAmountOperator
          stockLeft
          booked
          acquired
          used
          maxTickets
          isUnlimitedTickets
          descriptionEn
          descriptionIt
          descriptionEs
          isSoldOut
          __typename
        }
      }
    }
    images {
      edges {
        node {
          id
          image
        }
      }
    }
    isDefaultCampaign
    __typename
  }
`

export const eventCampaignDetailParts = `
  fragment eventCampaignDetailParts on CampaignNode {
    id
    slug
    status
    name
    nameEn
    nameIt
    nameEs
    image {
      url
      cache
    }
    stripePublicKey
    descriptionEn
    descriptionIt
    descriptionEs
    isAvailable
    shortDescriptionEn
    shortDescriptionIt
    shortDescriptionEs
    isDefaultCampaign
     supportedCampaign{
        id
        slug
        name
        nameEn
        nameIt
        nameEs
      }
    campaignType
    video
    deadline
    goalAmount
    fundedAmount
    donationCount
    isFeatured
    enableDonationNote
    startsAt
    campaignType
    eventAddress
    eventMapLink
    virtualEventLink
    eventStartsAt
    tiers {
      edges {
        node {
          id
          donationAmount
          donationAmountOperator
          stockLeft
          booked
          acquired
          used
          isUnlimitedTickets
          isSoldOut
          descriptionEn
          descriptionIt
          descriptionEs
        }
      }
    }
    __typename
  }
`

export const campaignCategoryParts = `
  fragment campaignCategoryParts on CampaignCategoryNode {
    id
    slug
    status
    nameEn
    nameIt
    nameEs
    icon
    __typename
  }
`
