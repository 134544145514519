import React, { useState } from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import { Tooltip } from "react-tooltip"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { useToast } from "@tmu/hooks"
import { CustomModal, TextInput, QRModal } from "@tmu/components/common"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faShareAlt as faShare } from "@fortawesome/pro-regular-svg-icons/faShareAlt"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons/faFacebookSquare"
import { faXTwitter } from "@fortawesome/free-brands-svg-icons/faXTwitter"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn"
import { faClone } from "@fortawesome/free-solid-svg-icons/faClone"
import theme from "@tmu/global/theme"
import { StyledSocialShare } from "../../partners/PartnerDetail/index.styles"
import {
  StyledShareModalContainer,
  StyledSocialMethodIcon,
  StyledSocialMethod,
  StyledSocialMethodTitle,
  StyledShareModalInputContainer,
} from "./index.styles"

const ShareComponent = ({
  url,
  description,
  slug,
  isNewCampaign,
  name,
  isMerchantCampaign,
  discount,
  storeLogo,
  partnerLogo,
  position = "left",
  iconColor = theme.colors.carrot,
}) => {
  const { formatMessage } = useIntl()
  const { success } = useToast()
  const _url = url || location.href
  const shareUrl = _url?.charAt(_url.length - 1) === "/" ? _url : `${_url}/`
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { isTablet } = getAllScreenTypes()

  const copiedMessage = formatMessage({
    id: "ui::copiedToClipboard",
    defaultMessage: "Copied to clipboard!",
  })

  const handleShare = () => {
    try {
      if (isTablet) {
        setIsModalOpen(true)
      } else {
        if (navigator && navigator.share)
          navigator.share({
            title: campaignName,
            url: location?.href || "https://trustmeup.com",
            text: campaignName || "TrustmeUp",
          })
      }
    } catch (err) {}
  }

  const handleCopyClick = () => {
    var input = document.createElement("input")
    input.value = shareUrl || url
    document.body.appendChild(input)
    input.select()

    document.execCommand("copy")
    document.body.removeChild(input)

    success(copiedMessage)
  }
  return <span />
  return (
    <>
      <StyledSocialShare>
        <StyledShareModalContainer position="right">
          {!isTablet && (
            <QRModal
              slug={slug}
              name={name}
              isMerchantCampaign={isMerchantCampaign}
              discount={discount}
              storeLogo={storeLogo}
              partnerLogo={partnerLogo}
            />
          )}
          <FontAwesomeIcon
            icon={faShare}
            onClick={handleShare}
            color={iconColor}
          />
        </StyledShareModalContainer>
      </StyledSocialShare>
      {isModalOpen && isTablet ? (
        <CustomModal
          isModalOpen={isModalOpen && isTablet}
          header={formatMessage({
            id: "campaign::detail::shareCampaign",
            defaultMessage: "Share",
          })}
          children={
            <>
              <StyledShareModalContainer position={position}>
                <StyledSocialMethodIcon>
                  <StyledSocialMethod currentColor={theme.colors.blue}>
                    <QRModal
                      slug={slug}
                      name={name}
                      isMerchantCampaign={isMerchantCampaign}
                      discount={discount}
                      storeLogo={storeLogo}
                      partnerLogo={partnerLogo}
                    />
                  </StyledSocialMethod>
                  <StyledSocialMethodTitle>
                    <FormattedMessage
                      id="campaign::detail::qrCode"
                      defaultMessage="QR code"
                    />
                  </StyledSocialMethodTitle>
                </StyledSocialMethodIcon>
                <StyledSocialMethodIcon>
                  <StyledSocialMethod
                    currentColor={theme.colors.navyBlueFacebook}>
                    <FacebookShareButton
                      url={shareUrl || url}
                      className={
                        isNewCampaign ? "socialMedia-shareButton" : ""
                      }>
                      <FontAwesomeIcon icon={faFacebookSquare} />
                    </FacebookShareButton>
                  </StyledSocialMethod>
                  <StyledSocialMethodTitle>Facebook</StyledSocialMethodTitle>
                </StyledSocialMethodIcon>
                <StyledSocialMethodIcon>
                  <StyledSocialMethod
                    currentColor={theme.colors.navyBlueLinkedin}>
                    <LinkedinShareButton
                      summary={description}
                      url={shareUrl || url}
                      windowWidth={1000}
                      windowHeight={1000}
                      className={
                        isNewCampaign ? "socialMedia-shareButton" : ""
                      }>
                      <FontAwesomeIcon icon={faLinkedinIn} />
                    </LinkedinShareButton>
                  </StyledSocialMethod>
                  <StyledSocialMethodTitle>LinkedIn</StyledSocialMethodTitle>
                </StyledSocialMethodIcon>
                <StyledSocialMethodIcon>
                  <StyledSocialMethod className="xtwitter">
                    <TwitterShareButton
                      url={shareUrl || url}
                      hashtags={["TMU", "TrustMeUp", slug]}
                      className={
                        isNewCampaign ? "socialMedia-shareButton" : ""
                      }>
                      <FontAwesomeIcon icon={faXTwitter} />
                    </TwitterShareButton>
                  </StyledSocialMethod>
                  <StyledSocialMethodTitle>Twitter</StyledSocialMethodTitle>
                </StyledSocialMethodIcon>
              </StyledShareModalContainer>
              <StyledShareModalInputContainer>
                <TextInput
                  newDesign
                  value={shareUrl || url}
                  readOnly={true}
                  inputClassName="fade-effect"
                  inputIcon={
                    <FontAwesomeIcon
                      icon={faClone}
                      className="input-icon"
                      color={theme.colors.blue}
                      onClick={handleCopyClick}
                    />
                  }
                  iconRight={0.9375}
                />
              </StyledShareModalInputContainer>
            </>
          }
          cancelButtonAction={() => {
            setIsModalOpen(false)
          }}
          isCloseIcon={true}
          isMobile={false}
          isFull={false}
          closeIconClassName="close-icon"
        />
      ) : null}
      <Tooltip
        arrowColor="transparent"
        effect="solid"
        place="bottom"
        type="info"
        textColor="black"
        backgroundColor="white"
        id="copyButton"
        className="copyButton"
        delayShow={500}
      />
    </>
  )
}

export default ShareComponent
