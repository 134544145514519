import React, { useEffect } from "react"
import { Router, useLocation } from "@reach/router"
import { injectIntl } from "gatsby-plugin-intl"
import PrivateRoute from "@tmu/components/PrivateRoute"
import { DashboardLayout } from "@tmu/layouts"
import {
  Donors,
  Partners,
  Merchants,
  CampaignTypes,
  ProfileRoute,
  SettingsRoute,
  SupporterCampaignsRoute,
  PartnersUsersRoute,
} from "@tmu/components/dashboard/routes"
import SEO from "@tmu/components/seo"

const Dashboard = ({ intl }) => {
  const { formatMessage } = intl
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <SEO
        title={`${formatMessage({
          id: "dashboard::title",
          defaultMessage: "Dashboard",
        })}`}
      />

      <Router basepath="/:locale/dashboard">
        <DashboardLayout path="/">
          <PrivateRoute component={CampaignTypes} path="campaign/*" />
          <PrivateRoute component={Donors} default />
          <PrivateRoute component={Donors} path="user/*" />
          <PrivateRoute component={Donors} path="donors/*" />
          <PrivateRoute component={Partners} path="charities/*" />
          <PrivateRoute component={Merchants} path="merchants/*" />
          <PrivateRoute component={ProfileRoute} path="profile/*" />
          <PrivateRoute component={SettingsRoute} path="settings/*" />
          <PrivateRoute
            component={SupporterCampaignsRoute}
            path="user/campaigns/*"
          />
          <PrivateRoute
            component={PartnersUsersRoute}
            path="charities/users/*"
          />
        </DashboardLayout>
      </Router>
    </>
  )
}

export default injectIntl(Dashboard)
