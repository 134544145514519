import React, { useState } from "react"
import ReactPaginate from "react-paginate"
import {
  StyledBreakContainer,
  StyledContainer,
  StyledPageSizeContainer,
  StyledPageSizeItem,
  StyledPageSizesLabel,
  StyledPagination,
  StyledPaginationWrapper,
} from "./index.styles"
import "react-paginate/theme/basic/react-paginate.css"

const Pagination = ({
  handlePageClick,
  handlePageSizeChange,
  pageCount,
  pageSizeItems = [10, 50, 100],
  initialPage,
  initialPageSize,
  pageSizesLabel,
  isSticky = true,
  isTransparent,
  customStyles,
  carrot,
}) => {
  const [pageSize, setPageSize] = useState(
    initialPageSize ?? pageSizeItems?.[0]
  )
  const handlePageSize = (size) => {
    if (pageSize !== size) {
      setPageSize(size)
      if (typeof handlePageSizeChange === "function") {
        handlePageSizeChange(size)
      }
    }
  }

  let wrapperClassNames = []

  if (isSticky) {
    wrapperClassNames.push("sticky")
  }
  if (isTransparent) {
    wrapperClassNames.push("transparent")
  }
  return (
    <StyledPaginationWrapper
      className={wrapperClassNames.join(" ")}
      style={customStyles}>
      <StyledContainer>
        <StyledPagination carrot={carrot}>
          {pageCount > 1 && (
            <ReactPaginate
              className="react-paginate"
              breakLabel={<StyledBreakContainer>...</StyledBreakContainer>}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={pageCount}
              renderOnZeroPageCount={null}
              initialPage={initialPage}
            />
          )}
        </StyledPagination>
        {pageSizeItems?.length > 1 && (
          <StyledPageSizeContainer>
            {pageSizesLabel && (
              <StyledPageSizesLabel>{pageSizesLabel}</StyledPageSizesLabel>
            )}
            {pageSizeItems.map((item, index) => {
              return (
                <StyledPageSizeItem
                  carrot={carrot}
                  className={pageSize === item && "active"}
                  key={index}
                  onClick={() => {
                    handlePageSize(item)
                  }}>
                  {item}
                </StyledPageSizeItem>
              )
            })}
          </StyledPageSizeContainer>
        )}
      </StyledContainer>
    </StyledPaginationWrapper>
  )
}

export default Pagination
