import styled from "@emotion/styled"
export const StyledFeatureCampaigns = styled.div``

export const StyledTable = styled.div`
  td,
  tr {
    padding-left: 1rem !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: unset !important;
    }
  }

  td,
  tr,
  span,
  div,
  p {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headline} !important;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
      font-family: ${(p) => p.theme.fontFamilies.regular} !important;
    }
  }

  tr th * {
    font-weight: ${(p) => p.theme.fontWeights.semiBolder} !important;
    font-family: ${(p) => p.theme.fontFamilies.headlineBig} !important;
  }

  thead > tr > th:first-child {
    padding-left: 0.8rem !important;

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      padding-left: unset !important;
    }
  }

  .blue-text {
    color: ${(p) => p.theme.colors.blue};
  }

  .align-right {
    text-align: right !important;
    justify-content: end !important;
  }

  .align-center {
    text-align: center !important;
    justify-content: center !important;
  }

  .mobile-date {
    color: ${(p) => p.theme.colors.disabled};
    font-size: 0.625rem;
    font-weight: ${(p) => p.theme.fontWeights.liteMedium} !important;
    font-family: ${(p) => p.theme.fontFamilies.regular} !important;
  }

  .voucher-text {
    font-size: 0.625rem;
     @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      font-size: 0.75rem;
    }
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
    }
  }

  .no-wrap {
    white-space: nowrap;
  }

  .tooltip {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.pitchBlack} !important;
    box-shadow: 0px 0px 3px rgba(122, 119, 118, 0.2); !important;
  }
`
export const StyledReceipt = styled.div`
  text-align: center;
  svg {
    height: 1.25rem;
    color: ${(p) => p.theme.colors.blue};
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      height: 1.75rem;
    }
    cursor: pointer;
  }
`

export const StyledPageWrapper = styled.div`
  margin-left: -1rem;

  @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
    margin-left: unset;
  }
`

export const StyledCampaignLink = styled.span`
  cursor: pointer;
  & > .icon {
    color: ${(p) => p.theme.colors.pitchBlack};
    height: 0.75rem;
    margin-left: 0.25rem;
    @media only screen and (min-width: ${(p) => p.theme.breakpoints.tablet}) {
      color: ${(p) => p.theme.colors.blue};
    }
  }

  & > .tooltip {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: ${(p) => p.theme.colors.pitchBlack};

    @media only screen and (min-width: ${(p) => p.theme.breakpoints.wide}) {
      font-size: 1rem;
    }
  }
`

export const StyledTotalInfo = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.whiteSmoke};
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`

export const StyledQuantity = styled.div`
  color: ${(p) => p.theme.colors.concreteGrey};
`
