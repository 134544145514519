import { useQuery, gql } from "@apollo/client"
import { useIntl } from "gatsby-plugin-intl"
import { useApolloApiClients } from "@tmu/apollo/client"
import { offerParts } from "@tmu/apollo/dashboard/fragments/offer"
import { useAuth } from "@tmu/hooks"
import { getValueForLocale } from "@tmu/utils/string"
import { OFFER_TYPE } from "@tmu/apollo/constants"

const useDefaultMerchant = (options = {}) => {
  const { locale, defaultLocale } = useIntl()
  const { isLoading, isAuthenticated, user } = useAuth()
  const { merchantClient } = useApolloApiClients()

  const merchantPart =
    options?.onlyAddress === true
      ? `
      fragment merchantPart on MerchantStoreNode {
        id
        name
        phoneNumber
        country
        city
        postCode
        addressLine1
        favoriteCharity {
        id
        slug
        name
        nameEn
        nameEs
        nameIt
        partner{
          logo
          logoEn
          logoEs
          logoIt
        }
      }
      inventoryRecord {
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
        booked
        reserved
        acquired
        used
      }
      allowUsersToSelectADifferentCampaign
      defaultOffer {
          country
          city
          postCode
          addressLine1
          location
        }
        }
      `
      : options?.isFullData === true
      ? `
    fragment merchantPart on MerchantStoreNode {
      id
      name
      email
      hideEmail
      communicationLanguage
      displayName
      description
      shortDescription
      descriptionEn
      shortDescriptionEn
      descriptionEs
      shortDescriptionEs
      descriptionIt
      shortDescriptionIt
      logo
      hideAddress
      taxId
      reviewStatus
      status
      maxPacDiscount
      website
      facebook
      instagram
      linkedin
      twitter
      youtube
      shopLink
      terms
      termsEn
      termsIt
      termsEs
      phoneNumber
      hidePhoneNumber
      maxPacDiscount
      country
      city
      postCode
      addressLine1
      defaultOffer {
        ...offerParts
      }
      paymentDestinations {
        accountName
        bankName
        iban
        country
        sortCode
        accountNumber
        bic
      }
      favoriteCharity {
        id
        slug
        name
        nameEn
        nameEs
        nameIt
        partner{
          logo
          logoEn
          logoEs
          logoIt
        }
      }
      allowUsersToSelectADifferentCampaign
      image
      images {
        edges {
          node {
            id
            order
            image
          }
        }
      }
    }
    ${offerParts}
  `
      : `
  fragment merchantPart on MerchantStoreNode {
    displayName
    defaultOffer {
      offerType
      country
      city
      postCode
      addressLine1
      location
    }
    name
    id
    status
    email
    inventoryRecord {
        maxTickets
        isUnlimitedTickets
        isSoldOut
        stockLeft
        booked
        reserved
        acquired
        used
    }
    totalFundedAmount
    totalDonationCount
    totalCampaignCount
    totalPacDiscountAmount
    fundedAmountForDisplay
    donationCountForDisplay
    campaignCountForDisplay
    pacDiscountAmountForDisplay
    isDisplayed
    logo
    pricingBundle {
      code
    }
    favoriteCharity {
        id
        slug
        name
        nameEn
        nameEs
        nameIt
        partner{
          logo
          logoEn
          logoEs
          logoIt
        }
      }
    allowUsersToSelectADifferentCampaign
    childStores {
      id
      name
      slug
      logo
      fundedAmountForDisplay
      donationCountForDisplay
      campaignCountForDisplay
      pacDiscountAmountForDisplay
      pricingBundle {
        code
      }
                
    }
  }
  `

  const defaultMerchantQuery = gql`
    query DefaultMerchantQuery {
      merchant {
        ...merchantPart
      }
    }
    ${merchantPart}
  `
  const defaultMerchantQueryWithBalance = gql`
    query defaultMerchantQueryWithBalance {
      merchant {
        ...merchantPart
      }
    }
    ${merchantPart}
  `

  const { loading, data, error, called, refetch } = useQuery(
    defaultMerchantQueryWithBalance,
    {
      fetchPolicy: "cache-and-network",
      client: merchantClient,
      skip:
        options?.skip ||
        isLoading ||
        !isAuthenticated ||
        !user ||
        !user?.isMerchant,
      ...options,
    }
  )

  const merchant = data?.merchant ?? null

  const defaultMerchant = merchant
    ? {
        ...merchant,
        logo:
          getValueForLocale(merchant, "logo", locale, defaultLocale) ??
          merchant?.logo,
      }
    : null

  const defaultOffer = defaultMerchant?.defaultOffer

  const isMerchantOffline = defaultOffer?.offerType === OFFER_TYPE.OFFLINE
  const isMerchantInternal = defaultOffer?.offerType === OFFER_TYPE.INTERNAL
  const isMerchantOnline = defaultOffer?.offerType === OFFER_TYPE.ONLINE
  const isMerchantCorporate = defaultOffer?.offerType === OFFER_TYPE.BRAND

  return {
    defaultMerchant,
    defaultOffer,
    isMerchantOffline,
    isMerchantInternal,
    isMerchantOnline,
    isMerchantCorporate,
    loading,
    error,
    refetch,
    called,
  }
}

export default useDefaultMerchant
