import { gql } from "@apollo/client"
import { donationParts } from "../../dashboard/fragments/transaction"

export const DONATION_QUERY = gql`
  query Donation($id: ID) {
    donation(id: $id) {
      ...donationParts
      stripeRequiresActionData
    }
  }
  ${donationParts}
`
export const DONATION_LISTING_QUERY = gql`
  query DonationsListing(
    $first: Int
    $after: String
    $orderBy: String
    $status: DonationStatuses
  ) {
    allDonations(
      first: $first
      after: $after
      orderBy: $orderBy
      status: $status
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      totalAmount
      edges {
        node {
          id
          campaign {
            id
            name
            nameEn
            nameIt
            nameEs
            slug
            status
            deadline
            user {
              fullName
            }
            partner {
              id
              name
            }
          }
          eventTickets {
            edges {
              node {
                id
                status
                voucherCode
              }
            }
          }
          created
          modified
          amount
          platformFee
          status
          type
          receipt
          userReceipt
          refundRequests {
            id
            status
          }
          earnedPac
        }
      }
      __typename
    }
  }
`
export const DONATION_SEARCH_QUERY = gql`
  query DonationSearch($pk: String, $id: ID) {
    donation(pk: $pk, id: $id) {
      id
      campaign {
        id
        name
        nameEn
        nameIt
        nameEs
        slug
        deadline
        partner {
          id
          name
        }
      }
      created
      modified
      amount
      platformFee
      status
      receipt
      userReceipt
      refundRequests {
        id
        status
      }
      earnedPac

      __typename
    }
  }
`

export const LIMITED_DONATION_SEARCH_QUERY = gql`
  query ($donationId: UUID) {
    limitedDonation(donationId: $donationId) {
      amount
      datetime
      status
      receiptNumber
      nonProfit
      campaign
      campaignSlug
      campaignDeadline
      donor
      earnedPac
      stripeRequiresActionData
    }
  }
`

export const SAVED_CARDS_QUERY = gql`
  query {
    savedCards {
      cardId
      name
      brand
      expMonth
      expYear
      last4
      country
      threeDSecureUsage
      isDefault
    }
  }
`

export const SAVED_PAYPAL_ACCOUNTS_QUERY = gql`
  query {
    savedPaypalAccounts {
      paypalId
      paypalEmail
      country
      isDefault
    }
  }
`

export const ALL_SAVED_CARDS_ACCOUNTS_QUERY = gql`
  query AllSavedAccounts {
    paypal: savedPaypalAccounts {
      paypalId
      paypalEmail
      country
      isDefault
    }
    cards: savedCards {
      cardId
      name
      brand
      expMonth
      expYear
      last4
      country
      threeDSecureUsage
      isDefault
    }
  }
`

export const DELETE_CARD_QUERY = gql`
  mutation DeleteCardMutation($input: DeleteCardMutationInput!) {
    deleteCard(input: $input) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const SUBSCRIPTIONS_QUERY = gql`
  query AllSubscriptions($stripePaymentMethodId: String) {
    allSubscriptions(
      first: 50
      stripePaymentMethodId: $stripePaymentMethodId
      status: "ACTIVE"
    ) {
      edges {
        node {
          id
          stripeSubscriptionId
          status
          canceledAt
          plan {
            id
            amount
            currency
            interval
          }
          campaign {
            id
            name
            nameEn
            nameIt
            nameEs
            isDefaultCampaign
            partner {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const LATEST_SUBSCRIPTION_STATUS_QUERY = gql`
  query Subscription($id: ID!) {
    subscription(id: $id) {
      id
      status
      paymentStatus
      actionUrl
      clientSecret
    }
  }
`

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: UpdateSubscriptionMutationInput!) {
    updateSubscription(input: $input) {
      subscription {
        created
        modified
        id
        status
        stripeSubscriptionId
        stripePaymentMethodId
        canceledAt
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription($input: CancelSubscriptionMutationInput!) {
    cancelSubscription(input: $input) {
      subscription {
        created
        modified
        id
        status
        stripeSubscriptionId
        stripePaymentMethodId
        canceledAt
      }
      errors {
        field
        messages
      }
    }
  }
`
export const CREATE_CLIENT_SECRET = gql`
  mutation CreateClientSecret($stripePaymentMethodType: String!) {
    createClientSecret(stripePaymentMethodType: $stripePaymentMethodType) {
      clientSecret
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_CLIENT_SECRET_WITH_REGISTRATION = gql`
  mutation CreateClientSecret(
    $stripePaymentMethodType: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    createClientSecret(
      stripePaymentMethodType: $stripePaymentMethodType
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      clientSecret
      token
      refreshToken
      userId
      errors {
        field
        messages
        __typename
      }
      __typename
    }
  }
`
