import React, { useEffect, useState } from "react"
import {
  Table,
  Date as CustomDate,
  CustomModal,
  DonationDetail,
  Spinner,
  Button,
  ClickToCopy,
} from "@tmu/components/common"
import { EmptyTable } from "@tmu/components/dashboard/dashboardCommon"
import { FormattedMessage, useIntl, navigate } from "gatsby-plugin-intl"
import Money from "@tmu/components/common/Money"
import { getValueForLocale } from "@tmu/utils/string"
import {
  StyledCampaignLink,
  StyledPageWrapper,
  StyledQuantity,
  StyledReceipt,
  StyledTable,
  StyledTotalInfo,
} from "./index.styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRotateRight } from "@fortawesome/pro-solid-svg-icons/faRotateRight"

import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import { getAllScreenTypes } from "@tmu/utils/mediaQueries"
import { parseId } from "@tmu/utils/auth"
import { useDonations } from "@tmu/hooks"
import { PER_PAGE } from "@tmu/apollo/constants"
import { faPlus } from "@fortawesome/pro-light-svg-icons/faPlus"
import { format } from "date-fns"
import { Tooltip } from "react-tooltip"

const DonationTable = ({
  dashboardType,
  isStatsBar,
  onRowSelect,
  donationsData,
  columnsToHide = [],
  filters,
}) => {
  const { formatMessage, locale, defaultLocale } = useIntl()
  const [donationDetailStatus, setDonationDetailStatus] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [sortInfo, setSortInfo] = useState([{ id: "date", desc: true }])
  const [firstLoad, setFirstLoad] = useState(false)
  const { donations: donationService } = useDonations({ dashboardType })
  const donations = donationsData || donationService?.donations
  const donationData = donationService?.donationsData
  const pageInfo = donationService?.pageInfo
  const totalCount = donationData?.allDonations?.totalCount
  const totalAmount = donationData?.allDonations?.totalAmount
  const perPage = PER_PAGE
  const variables = {
    first: perPage,
    orderBy: "-created",
    ...filters,
  }

  useEffect(() => {
    if (donationsData) {
      setFirstLoad(true)
      return
    }
    donationService?.callDonations({
      variables,
      onCompleted: () => setFirstLoad(true),
    })
  }, [])

  useEffect(() => {
    if (firstLoad) {
      donationService?.callDonationsRefetch(variables)
    }
  }, [filters])

  const { isTablet } = getAllScreenTypes()

  const data = !donations
    ? []
    : donations?.map((item) => {
        return {
          donationId: parseId(item?.id),
          campaignName: getValueForLocale(
            item?.campaign,
            "name",
            locale,
            defaultLocale
          ),
          slug: item?.campaign?.slug,
          pacs: item?.earnedPac,
          amount: item?.amount,
          date: item?.created,
          name:
            item?.donorName ||
            (item?.user?.email?.includes("non.registered")
              ? item?.intendedUser?.firstName +
                " " +
                item?.intendedUser?.lastName
              : item?.user?.displayName ?? item?.user?.fullName),
          receipt:
            dashboardType === "donors" ? item?.userReceipt : item?.receipt,
          voucherCodes: item?.eventTickets?.edges
            ?.map((item) => item.node?.voucherCode)
            .filter((item) => item),
          endDate: item?.campaign?.deadline
            ? format(new Date(item?.campaign?.deadline), "dd.MM.yy")
            : "",
          donation: item,
          type: item?.type,
          quantity: item?.donationProvision?.quantity,
        }
      })

  const campaignAndDonationIdText = formatMessage({
    id: "dashboard::tableHeader::campaignNameDonationId",
    defaultMessage: "Campaign Name / Donation Id",
  })

  const campaignText = formatMessage({
    id: "dashboard::tableHeader::campaign",
    defaultMessage: "Campaign",
  })

  const openDonationDetail = (row) => {
    setSelectedRow(row)
    setDonationDetailStatus(true)
  }

  const closeDonationDetail = () => {
    setSelectedRow(null)
    setDonationDetailStatus(false)
  }

  const handleCampaignClick = (slug) => navigate(`/campaigns/${slug}`)

  const voucherText = formatMessage({
    id: "orders::table::Voucher",
    defaultMessage: "Voucher",
  })

  const expirationDateText = formatMessage({
    id: "orders::table::expirationDate",
    defaultMessage: "expiration date",
  })
  const recurringText = formatMessage({
    id: "orders::table::recurringText",
    defaultMessage: "Recurring",
  })

  const columns = [
    {
      Header: (props) => (
        <div className="header-text">
          {isTablet ? campaignAndDonationIdText : campaignText}
        </div>
      ),
      Cell: ({ row }) => {
        const voucherCodeContent = row?.original?.voucherCodes?.map(
          (voucherCode, i) => {
            return (
              <p className="mobile-date no-wrap voucher-text">
                {`${voucherText}${
                  row?.original?.voucherCodes.length > 1 ? "-" + (i + 1) : ""
                }: ${voucherCode} ${
                  row?.original?.endDate?.length
                    ? expirationDateText + " " + row?.original?.endDate
                    : ""
                }`}
              </p>
            )
          }
        )

        return (
          <div>
            <p>
              <StyledCampaignLink
                className={isTablet && "blue-text"}
                onClick={(evt) => {
                  evt.preventDefault()
                  evt.stopPropagation()
                  handleCampaignClick(row?.original?.slug)
                }}>
                {row?.original?.campaignName}

                {row?.original?.type === "SUBSCRIPTION" ? (
                  <>
                    <FontAwesomeIcon
                      className="icon"
                      data-tooltip-content={recurringText}
                      data-tooltip-id="recurringBtn"
                      icon={faRotateRight}
                    />
                    {isTablet ? (
                      <Tooltip
                        place="bottom"
                        id="recurringBtn"
                        className="tooltip"
                        arrowColor="transparent"
                      />
                    ) : null}
                  </>
                ) : null}
              </StyledCampaignLink>
              {row?.original?.name?.length > 0 && " - " + row?.original?.name}
            </p>
            {isTablet ? (
              <>
                <p>
                  <ClickToCopy
                    text={row?.original?.donationId}
                    tooltipOptions={{
                      className: "tooltip",
                      arrowColor: "transparent",
                    }}>
                    {row?.original?.donationId}
                  </ClickToCopy>
                </p>
                {voucherCodeContent}
              </>
            ) : (
              <>
                <p className="mobile-date">
                  <CustomDate
                    value={row?.original?.date}
                    showYear={true}
                    second="numeric"
                  />
                </p>
                {voucherCodeContent}
              </>
            )}
          </div>
        )
      },
      accessor: "campaignName",
      disableSort: true,
      headerStyle: {
        minWidth: "15rem",
        maxWidth: "15rem",
        width: "15rem",
      },
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-right">
          <FormattedMessage
            id="dashboard::tableHeader::donationAmount"
            defaultMessage="Amount"
          />
        </div>
      ),
      Cell: (props) => (
        <div className="align-right">
          <Money value={props?.value} />
          {props.row?.original?.quantity ? (
            <StyledQuantity>
              {props.row?.original?.quantity}{" "}
              <FormattedMessage
                id="dashboard::table::ticket"
                defaultMessage="Ticket"
              />
            </StyledQuantity>
          ) : null}
        </div>
      ),
      accessor: "amount",
      headerClassName: "align-header-right",
    },
    {
      Header: (props) => (
        <div className="header-text align-right">
          <FormattedMessage
            id="dashboard::tableHeader::pacsReceived"
            defaultMessage="PACs Received"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <div className="align-right">
          {value && <Money value={value} currency={false} />}
        </div>
      ),
      accessor: "pacs",
      headerClassName: "align-header-right",
    },
    {
      Header: (props) => (
        <div className="header-text header-icon align-center">
          <FormattedMessage
            id="dashboard::tableHeader::dateAndTime"
            defaultMessage="Date / Time"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <div className="align-center">
          <CustomDate value={value} showYear={true} second="numeric" />
        </div>
      ),
      accessor: "date",
      headerClassName: "align-header-center",
    },
    {
      Header: (props) => (
        <div className="header-text align-center">
          <FormattedMessage
            id="dashboard::tableHeader::receipt"
            defaultMessage="receipt"
          />
        </div>
      ),
      Cell: ({ value }) => (
        <StyledReceipt>
          {value && (
            <FontAwesomeIcon
              icon={faFileAlt}
              disabled={value?.length === 0}
              onClick={() => window.open(value, "_blank")}
            />
          )}
        </StyledReceipt>
      ),
      accessor: "receipt",
      disableSort: true,
    },
  ]

  const hiddenColumns = isTablet
    ? [...columnsToHide]
    : ["pacs", "date", "receipt"]

  const handleRowClick = (row, index) => {
    openDonationDetail(row)
    if (typeof onRowSelect === "function") {
      onRowSelect(row, index)
    }
  }

  const handleSort = (sortBy) => {
    setSortInfo(sortBy)

    const sortValue = getSortValue(sortBy)
    donationService?.callDonations({
      variables: { ...variables, orderBy: sortValue },
    })
  }

  const getSortValue = (sortBy) => {
    const sortData = sortBy?.at(0)

    const direction = sortData?.desc === true ? "-" : ""
    let sortField = null

    switch (sortData?.id) {
      case "date":
        sortField = "created"
        break
      case "amount":
        sortField = "amount"
        break
      case "pacs":
        sortField = "earnedPac"
        break

      default:
        sortField = null
        break
    }
    return sortField ? direction + sortField : null
  }

  const handleLoadMore = () => {
    donationService?.fetchMore({
      variables: {
        after: pageInfo?.endCursor,
        orderBy: getSortValue(sortInfo),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult?.allDonations?.edges
        const pageInfo = fetchMoreResult?.allDonations?.pageInfo

        const newResult = newEdges?.length
          ? {
              ...prev,
              allDonations: {
                ...prev.allDonations,
                edges: [...prev.allDonations?.edges, ...newEdges],
                pageInfo,
              },
            }
          : prev

        return newResult
      },
    })
  }

  return !firstLoad || donationService?.loading ? (
    <Spinner condensed />
  ) : donations.length > 0 ? (
    <StyledPageWrapper>
      {isStatsBar !== false && isTablet && (
        <StyledTotalInfo>
          <div>
            {totalCount}{" "}
            <FormattedMessage
              id="dashboard::tableHeader::donations"
              defaultMessage="donations"
            />
          </div>
          {totalAmount ? (
            <div>
              <Money value={Number(totalAmount)} />{" "}
              <FormattedMessage
                id="dashboard::tableHeader::total"
                defaultMessage="total"
              />
            </div>
          ) : null}
        </StyledTotalInfo>
      )}
      <StyledTable>
        <Table
          columns={columns}
          data={data}
          hiddenColumns={hiddenColumns}
          headerLineSeperator={true}
          onRowClick={!isTablet ? handleRowClick : null}
          initialSort={sortInfo}
          handleSort={handleSort}
          options={{ sort: true }}
        />
      </StyledTable>
      <CustomModal
        isModalOpen={!isTablet && selectedRow && donationDetailStatus}
        cancelButtonAction={closeDonationDetail}
        children={
          <DonationDetail
            donation={selectedRow?.original?.donation}
            rowData={selectedRow}
          />
        }
        isMobile={!isTablet}
        isCloseIcon={true}
        style={{
          overflow: "hidden",
        }}
      />
      {pageInfo?.hasNextPage && (
        <div data-testid="view-more">
          <Button
            data-testid="button-view-more"
            variant="text"
            label="View More"
            onClick={handleLoadMore}
            style={{
              margin: "1rem auto",
            }}>
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage id="ui::viewMore" defaultMessage="View More" />
          </Button>
        </div>
      )}
    </StyledPageWrapper>
  ) : (
    <EmptyTable
      emptyMessage={
        dashboardType === "donors" ? (
          <FormattedMessage
            id="dashboard::donors::donations::empty"
            defaultMessage="You haven't made a donation yet"
          />
        ) : (
          <FormattedMessage
            id="dashboard::partner::donations::empty"
            defaultMessage="It seems no one has made a donation yet"
          />
        )
      }
    />
  )
}

export default DonationTable
